<template>
  <div
    class="bg_col_fff bor_rad_8 box_sha_0_0_8_8_black_80_per p_16 m_0_16 m_t_16"
  >
    <div class="flex_1 font_16 font_bold">进件转化</div>

    <!-- 图表 -->
    <Chart v-if="!loading" class="h_88_vw m_t_8" :options="options" />
    <div v-else class="dis_flex ju_con_cen">
      <van-loading type="spinner" />
    </div>

    <!--  -->
  </div>
</template>

<script>
import { numAlign, getMaxInArray } from '@/utils/tools';
import { brokerDashboardLoanConversionStatisticsPost } from '@/api/index';

/* const colors = [
  '#3fb1e3',
  '#6be6c1',
  '#626c91',
  '#a0a7e6',
  '#c4ebad',
  '#96dee8',
]; */

export default {
  components: {
    Chart: () => import('@/components/Chart.vue'),
  },
  data() {
    return {
      loading: false,
      options: {},

      //
    };
  },
  computed: {},
  mounted() {
    this.getTrendChart();

    //
  },
  methods: {
    // 获取 进件走势图
    async getTrendChart() {
      this.loading = true;

      const res = await brokerDashboardLoanConversionStatisticsPost({
        condition: this.condition,
      });

      this.loading = false;

      let data = [
        { name: '颂车进件量', value: res.scLoanCount },
        { name: '额度批复量', value: res.approvalCount },
        { name: '交易量', value: res.dealCount },
        { name: '进件量', value: res.loanCount },
        { name: '预审通过量', value: res.preCount },
        { name: '签约完成量', value: res.signCount },
      ];

      // 排序
      data = data.sort((pre, cur) => {
        return cur.value - pre.value;
      });

      // 数值显示
      const names = [...data].reverse().map((row) => row.name);
      const values = [...data].reverse().map((row) => +row.value || 0);

      // 最大进件笔数
      let numMax = numAlign(getMaxInArray([...data].map((row) => row.value)));
      numMax += numMax * 0.1;

      // 转化率计算
      /* const _values = [...values].reverse();
      const ratio = _values
        .map((row, index) => {
          if (index === 0 || row === 0) return 0;
          let _prev = _values[index - 1];
          let _ratio = 0;
          if (_prev === 0) {
            _ratio = -1;
          } else {
            _ratio = (row / _prev) * 100;
          }
          if (_ratio === -1) return 0;
          return /\d|\./.test(_ratio) ? _ratio.toFixed(2) : 0;
        })
        .slice(1)
        .reverse(); */

      const colors = [
        '#3f81e3',
        '#47e6b4',
        '#ffb82d',
        '#a0a7e6',
        '#fc774e',
        '#d666e8',
        '#4ec0ff',
      ];

      this.options = {
        grid: {
          top: '1%',
          bottom: '1%',
        },
        tooltip: {
          trigger: 'axis',
          showContent: false, // 只做高亮使用，不展示浮层
          axisPointer: {
            type: 'none',
            axis: 'y',
          },
        },
        xAxis: [
          // 该轴用于模拟漏斗数据
          {
            show: false,
            type: 'value',
            scale: true,
            min: -numMax,
            max: numMax,
          },
          // 数值柱状图
          {
            show: false,
            type: 'value',
            scale: true,
            min: numMax,
            max: 0,
            inverse: true,
          },
          {
            show: false,
            type: 'value',
            min: 0,
            max: numMax,
          },
        ],
        yAxis: [
          // 数据柱状图
          {
            show: false,
            type: 'category',
            data: [...data].map((row) => row.name),
          },
          // 转化率右侧往左侧伸展
          {
            show: false,
            type: 'category',
            top: 140,
            position: 'right',
            data: [...data].map((row) => row.name),
          },
        ],
        series: [
          // 右侧柱状伸展
          {
            type: 'bar',
            xAxis: 0,
            stack: true,
            barWidth: '80%',
            z: 2,
            itemStyle: {
              color: (params) => colors[params.dataIndex % colors.length],
              borderRadius: [0, 16, 16, 0],
            },
            data: values.map((row) => (row ? row : numMax * 0.5)),
            animationEasing: 'elasticOut',
            animationDelay: (idx) => idx * 20,
          },
          // 左侧柱状伸展
          {
            type: 'bar',
            stack: true,
            barWidth: '80%',
            xAxis: 0,
            z: 3,
            barGap: '-100%',
            itemStyle: {
              color: (params) => colors[params.dataIndex % colors.length],
              borderRadius: [16, 0, 0, 16],
            },
            data: values.map((row) => (row ? row * -1 : numMax * -0.5)),
            animationEasing: 'elasticOut',
            animationDelay: (idx) => idx * 20,
          },
          // 数值叠加展示
          {
            type: 'bar',
            xAxisIndex: 2,
            barWidth: '80%',
            barGap: '-100%',
            z: 4,
            itemStyle: {
              color: 'rgba(255, 0, 0, 0)',
            },
            label: {
              show: true,
              color: '#fff',
              fontSize: 14,
              textBorderColor: 'rgba(0, 0, 0, 0.5)',
              textBorderWidth: 2,
              formatter: (param) => {
                return (
                  names[param.dataIndex] + ' ' + values[param.dataIndex] + ' 笔'
                );
              },
            },
            data: values.map(() => numMax),
          },
          // 转化率错行连接
          /* {
            type: 'pictorialBar',
            xAxisIndex: 1,
            yAxisIndex: 0,
            barWidth: '100%',
            animationDelay: 600,
            z: 1,
            symbol:
              'path://M875,442 L215,442 C206.715729,442 200,435.284271 200,427 L200,203 C200,194.715729 206.715729,188 215,188 L875,188 L875,188',
            symbolPosition: 'end',
            symbolSize: ['54%', '100%'],
            symbolOffset: [0, '-50%'],
            itemStyle: {
              color: 'rgba(255, 0, 0, 0)',
              borderColor: '#09f',
              borderWidth: 1,
            },
            label: {
              show: true,
              fontSize: 14,
              position: ['50%', '-15%'],
              color: '#09f',
              textBorderColor: 'rgba(255, 255, 255, 1)',
              textBorderWidth: 4,
              formatter: (param) => {
                return ratio[param.dataIndex] + ' %';
              },
            },
            data: values.slice(1).map(() => numMax / 2),
          }, */
        ],
      };

      // this.options = {
      //   /* title: {
      //     text: 'Funnel',
      //   }, */
      //   tooltip: {
      //     trigger: 'item',
      //     // formatter: '{b} : {d}%',
      //     formatter: (o) => {
      //       return [`${o.marker}<b>${o.name}</b> ${o.value} 笔`].join('<br />');
      //     },
      //   },
      //   legend: {
      //     data: [
      //       '颂车进件量',
      //       '额度批复量',
      //       '交易量',
      //       '进件量',
      //       '预审通过量',
      //       '签约完成量',
      //     ],
      //   },
      //   series: [
      //     {
      //       name: '漏斗',
      //       type: 'funnel',
      //       left: '10%',
      //       top: 56,
      //       bottom: 24,
      //       width: '80%',
      //       min: 0,
      //       // max: 100,
      //       minSize: '0%',
      //       maxSize: '100%',
      //       sort: 'descending',
      //       gap: 4,
      //       label: {
      //         show: true,
      //         position: 'inside',
      //         formatter: (o) => {
      //           return `${o.name} ${o.value} 笔`;
      //         },
      //       },
      //       itemStyle: {
      //         color: (params) => colors[params.dataIndex % colors.length],
      //       },
      //       emphasis: {
      //         label: {
      //           fontSize: 20,
      //         },
      //       },
      //       data: [
      //         { value: data.scLoanCount, name: '颂车进件量' },
      //         { value: data.approvalCount, name: '额度批复量' },
      //         { value: data.dealCount, name: '交易量' },
      //         { value: data.loanCount, name: '进件量' },
      //         { value: data.preCount, name: '预审通过量' },
      //         { value: data.signCount, name: '签约完成量' },
      //       ],
      //     },
      //   ],
      // };

      //
    },

    //
  },
};
</script>
